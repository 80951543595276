import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthorisationService {

  private currentExamSource: Subject<void> = new Subject<void>();
  public currentExamChange$ = this.currentExamSource.asObservable();

  public setAccessToken(accessToken: string): void {
    sessionStorage.setItem('AccessToken', accessToken);
  }

  public getAccessToken(): string {
    return sessionStorage.getItem('AccessToken') as string;
  }

  public clearAccessToken(): void {
    this.currentExamSource.next();
    sessionStorage.removeItem('AccessToken');
  }

  public getAuthHeader(): HttpHeaders {
    const accessToken: string = this.getAccessToken();

    if (!(!!accessToken)) { return new HttpHeaders(); }

    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Authorization', accessToken);
    headers = headers.append('timeout', '30000');

    return headers;
  }
}
