import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { InvigilatorAuthGuard } from './guard/invigilator-auth.guard';
import { ErrorPageComponent } from './site/error/error-page/error-page.component';
import { NotFoundComponent } from './site/not-found/not-found.component';

const routes: Routes = [
  // { path: '', loadChildren: () => import('./site/home/home.module').then(m => m.HomeModule) },
  { path: '', loadChildren: () => import('./site/login/login.module').then(m => m.LoginModule) },
  { path: 'exam', loadChildren: () => import('./site/exam/exam.module').then(m => m.ExamModule), canLoad: [InvigilatorAuthGuard] },
  { path: 'error/:errorCode', component: ErrorPageComponent },
  { path: '**', component: NotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
