import { Router } from '@angular/router';
import { AuthorisationService } from '../authorisation/authorisation.service';
import { Injectable } from '@angular/core';
import { ApplicationError } from '../../model/error';

export enum ErrorCode {
    None = 0,
    Unknown = 1,
    ServerUnavailable = 2,
    ServerTimeout = 3,
    InvalidAccessToken = 10000,
    ExamHasEnded,
    PreviousExamPresentOnDevice,
    ExamNotStarted,
    CandidateExamNotStarted,
    MismatchingExamCandidateQuestion,
  MismatchingExamCandidateQuestionAnswer,
    InvalidExamState,
    UploadInvalidChecksum,
    UploadIncorrectPin,
  UploadInvalidPin,
  ExamNotAvailable = 10012
}

@Injectable({
    providedIn: 'root'
})
export class ApplicationErrorHandler {

    constructor(private router: Router, private authorisationService: AuthorisationService) { }

    private readonly errors: ApplicationError[] = [{
        technicalName: 'The error code is either invalid or nonexistant, which I guess is the same thing here',
        friendlyName: 'Server Error',
        errorCode: ErrorCode.None,
        actionText: null,
        action: () => {}
    },
    {
        technicalName: 'The error code is either invalid or nonexistant, which I guess is the same thing here',
        friendlyName: 'Unknown Error',
        errorCode: ErrorCode.Unknown,
        actionText: null,
        action: () => {}
    },
    {
        technicalName: 'The error code is either invalid or nonexistant, which I guess is the same thing here',
        friendlyName: 'Server Unavailable',
        errorCode: ErrorCode.ServerUnavailable,
        actionText: null,
        action: () => {}
    },
    {
        technicalName: 'The error code is either invalid or nonexistant, which I guess is the same thing here',
        friendlyName: 'Server Timeout',
        errorCode: ErrorCode.ServerTimeout,
        actionText: null,
        action: () => {}
    },
    {
        technicalName: 'Invalid or missing Access Token',
        friendlyName: 'It looks like you aren\'t logged in. Please log in.',
        errorCode: ErrorCode.InvalidAccessToken,
        actionText: 'Ok',
        action: () => {
            this.authorisationService.clearAccessToken();
            this.router.navigateByUrl('login');
        }
    },
      {
        technicalName: 'The error code is either invalid or nonexistant, which I guess is the same thing here',
        friendlyName: 'Exam has ended',
        errorCode: ErrorCode.ExamHasEnded,
        actionText: null,
        action: () => { }
      },
      {
        technicalName: 'The error code is either invalid or nonexistant, which I guess is the same thing here',
        friendlyName: 'Exam is not available',
        errorCode: ErrorCode.ExamNotAvailable,
        actionText: null,
        action: () => { }
      },
    {
        technicalName: 'The error code is either invalid or nonexistant, which I guess is the same thing here',
        friendlyName: 'Unknown Error',
        errorCode: ErrorCode.PreviousExamPresentOnDevice,
        actionText: null,
        action: () => {}
    },
    {
        technicalName: 'The error code is either invalid or nonexistant, which I guess is the same thing here',
        friendlyName: 'Exam not started',
        errorCode: ErrorCode.ExamNotStarted,
        actionText: 'Go To Exam Start',
        action: () => {
            this.router.navigateByUrl('exam/start');
        }
    }, {
        technicalName: 'The error code is either invalid or nonexistant, which I guess is the same thing here',
        friendlyName: 'Candidate exam not started',
        errorCode: ErrorCode.CandidateExamNotStarted,
        actionText: null,
        action: () => {}
    }, {
        technicalName: 'The error code is either invalid or nonexistant, which I guess is the same thing here',
        friendlyName: 'Unknown Error',
        errorCode: ErrorCode.MismatchingExamCandidateQuestion,
        actionText: null,
        action: () => {}
    }, {
        technicalName: 'The error code is either invalid or nonexistant, which I guess is the same thing here',
        friendlyName: 'Unknown Error',
        errorCode: ErrorCode.MismatchingExamCandidateQuestionAnswer,
        actionText: null,
        action: () => {}
    }, {
        technicalName: 'The error code is either invalid or nonexistant, which I guess is the same thing here',
        friendlyName: 'Invalid exam state, please contact Highfield',
        errorCode: ErrorCode.InvalidExamState,
        actionText: null,
        action: () => {}
    }, {
        technicalName: 'The error code is either invalid or nonexistant, which I guess is the same thing here',
        friendlyName: 'Invalid Checksum',
        errorCode: ErrorCode.UploadInvalidChecksum,
        actionText: null,
        action: () => {}
    }, {
        technicalName: 'The error code is either invalid or nonexistant, which I guess is the same thing here',
        friendlyName: 'Unable to find candidate',
        errorCode: ErrorCode.UploadInvalidPin,
        actionText: null,
        action: () => {}
    }, {
        technicalName: 'The error code is either invalid or nonexistant, which I guess is the same thing here',
        friendlyName: 'Incorrect PIN',
        errorCode: ErrorCode.UploadIncorrectPin,
        actionText: null,
        action: () => {}
    }];

    public getErrorByErrorIdentifier(errorCode: number): ApplicationError {
        const error = this.errors.find(x => x.errorCode === errorCode);
        return error || this.errors[0];
    }
}
