import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApplicationError } from '../../../model/error';
import { ApplicationErrorHandler } from '../../../service/error/application-error-handler';
import { ErrorDialogData } from '../../../model/error-dialog-data';

@Component({
  selector: 'app-error-modal',
  templateUrl: './error-modal.component.html',
  styleUrls: ['./error-modal.component.scss']
})
export class ErrorModalComponent implements OnInit {
  public error!: ApplicationError;
  public id!: string | undefined;
  public technicalDetailsPanelOpen: boolean = false;
  public tooltipText: string = 'Copy me!';

  private defaultError: ApplicationError = {
    technicalName: 'The error code is either invalid or nonexistant, which I guess is the same thing here',
    friendlyName: 'Unknown Error',
    errorCode: 0,
    actionText: null,
    action: () => {}
  };

  constructor(private applicationErrorHandler: ApplicationErrorHandler, private dialogRef: MatDialogRef<ErrorModalComponent>, @Inject(MAT_DIALOG_DATA) private data: ErrorDialogData) { }

  ngOnInit(): void {
    this.id = this.data.sentryId;
    let errorCode: number = 0;
    try {
      errorCode = Number(this.data.errorCode);
    } catch {
      errorCode = 0;
    }

    this.error = this.applicationErrorHandler.getErrorByErrorIdentifier(errorCode) || this.defaultError;
  }

  public triggerErrorAction(): void {
    if (this.error.action) {
      this.error.action();
    }

    this.dialogRef.close();
  }

  public toggleTechnicalDetailsPane(): void {
    this.technicalDetailsPanelOpen = !this.technicalDetailsPanelOpen;
  }

  public copy(e: Event): void {
    e.stopPropagation();
    e.preventDefault();
    navigator.clipboard.writeText(this.id as string).then(() => {
      this.setTooltipTextSuccess();
    }).catch(() => {
      this.setTooltipTextFailure();
    });
  }

  public setTooltipTextDefault(): void {
    setTimeout(() => {
      this.tooltipText = 'Copy me!';
    }, 50);
  }

  public setTooltipTextSuccess(): void {
    this.tooltipText = 'Successfully copied!';
  }

  public setTooltipTextFailure(): void {
    this.tooltipText = 'Unable to copy!';
  }
}
