import { Injectable } from '@angular/core';
import { UrlTree, Route, UrlSegment, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthorisationService } from '../service/authorisation/authorisation.service';

@Injectable({
  providedIn: 'root'
})
export class InvigilatorAuthGuard  {
  constructor(private authorisationService: AuthorisationService, private router: Router) { }

  public canLoad(route: Route, segments: UrlSegment[]): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    const authorised = !!this.authorisationService.getAccessToken();

    if (!authorised) {
      this.router.navigateByUrl('/login');
    }

    return authorised;
  }
}
