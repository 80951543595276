import { BrowserModule } from '@angular/platform-browser';
import { ErrorHandler, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CommonComponentsModule } from './site/common/common.module';
import * as Sentry from '@sentry/browser';
import { SentryErrorHandler } from './error-handler';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './site/material/material.module';
import { HttpClientModule } from '@angular/common/http';
import { ErrorPageComponent } from './site/error/error-page/error-page.component';
import { ErrorModalComponent } from './site/error/error-modal/error-modal.component';
import { NotFoundComponent } from './site/not-found/not-found.component';
import { environment } from '../environments/environment';
import { FormsModule } from '@angular/forms';

Sentry.init({
  dsn: 'https://37d8bba73539406596a0e02e8b6b6beb@o341742.ingest.sentry.io/5356011',
  integrations: [
    Sentry.browserTracingIntegration(),
  ],
  sampleRate: environment.sentryTraceRate,
  release: environment.sentryReleaseVersion,
  environment: environment.sentryEnvironment
});

@NgModule({
  declarations: [
    AppComponent, ErrorPageComponent, ErrorModalComponent, NotFoundComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CommonComponentsModule,
    BrowserAnimationsModule,
    FormsModule,
    MaterialModule,
    HttpClientModule
  ],
  providers: [
    { provide: ErrorHandler, useClass: SentryErrorHandler },
    { provide: 'servicesBaseUrl', useValue: environment.servicesBaseUrl },
    { provide: 'signalRBaseUrl', useValue: environment.signalRBaseUrl },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
